import React, { useEffect, useRef, useState } from 'react';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';

import { Flex } from 'styles';
import i18n from 'utils/lang';
import { useModal } from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import Button, { Type as ButtonType } from 'components/Button';
import { ButtonContainer } from 'widgets/Report/components/Modal/style';
import { CommentTypeExtended } from 'api/types/comment';

import { CommentTypeContainer, CommentTypeListContainer, TextArea, TitleCommentType } from './style';
import { useComment } from './provider';
import { useTaskComment } from './taskProvider';
import { CounterByTask } from 'api/types/counter';

type CommentTypeProps = {
	commentType: CommentTypeExtended;
}

const CommentType: React.FC<CommentTypeProps> = ({ commentType }) => {
	const { updateCommentType } = useComment();
	const [comment, setComment] = useState(commentType.comment.content ?? '');
	const debouncedComment = useDebounce(comment, 750);

	useEffect(() => {
		if (comment !== commentType.comment.content) {
			updateCommentType(comment, commentType);
		}
	}, [debouncedComment]);

	return (
		<CommentTypeContainer flexDirection='column' flexGrow={1} gap="10px">
			<TitleCommentType>{commentType.label}</TitleCommentType>
			<TextArea isModal value={comment} onChange={(e) => setComment(e.target.value)} />
		</CommentTypeContainer>
	)
}

const ModalComment: React.FC = () => {
	const { pop } = useModal();
	const { comment, commentTypes, setComment } = useComment();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	useOnClickOutside(modalRef, pop);

	const updateComment = (value: string): void => {
		setComment(value.substring(0, 2048 - 1))
	}

	return (
		<ModalContainer ref={modalRef}>
			<ModalHeader title={i18n.t('comment')} pop={pop} />
			<Flex height="85%" gap="20px" justifyContent='space-between'>
				<Flex flexDirection='column' flexGrow={1}>
					<TextArea isModal value={comment} onChange={(e) => updateComment(e.target.value)} />
				</Flex>
				{commentTypes && commentTypes.length > 0 && (
					<CommentTypeListContainer flexDirection='column' flexGrow={1} gap="5px">
						{
							commentTypes && commentTypes
								.sort((({ order: aOrder }, { order: bOrder }) => aOrder - bOrder))
								.map((ct, idx) => (
									<CommentType
										key={`commenttype-${ct._id}-${ct.comment._id ?? `undefined-${idx}`}`}
										commentType={ct}
									/>
								))
						}
					</CommentTypeListContainer>
				)}
			</Flex>
			<ButtonContainer>
				<Button type={ButtonType.modalValidator} onClick={pop}>
					{i18n.t('close')}
				</Button>
			</ButtonContainer>
		</ModalContainer>
	);
};


type ModalTaskCommentProps = {
	task: CounterByTask;
}

export const ModalTaskComment: React.FC<ModalTaskCommentProps> = ({ task }) => {
	const { getCommentByTask, updateCommentByTask } = useTaskComment();
	const originalComment = getCommentByTask(task);
	const { pop } = useModal();

	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const [comment, setComment] = useState(originalComment?.content ?? '');
	const debouncedComment = useDebounce(comment, 750);

	useOnClickOutside(modalRef, pop);

	useEffect(() => {
		if (comment !== originalComment?.content) {
			updateCommentByTask(task, comment);
		}
	}, [debouncedComment])

	return (
		<ModalContainer ref={modalRef}>
			<ModalHeader title={i18n.t('comment')} pop={pop} />
			<Flex height="85%" gap="20px" justifyContent='space-between'>
				<TextArea isModal value={comment} onChange={(e) => setComment(e.target.value)} />
			</Flex>
			<ButtonContainer>
				<Button type={ButtonType.modalValidator} onClick={pop}>
					{i18n.t('close')}
				</Button>
			</ButtonContainer>
		</ModalContainer>
	);
}

export default ModalComment;
