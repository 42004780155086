import React, { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import i18n from 'utils/lang';
import { ModalProvider, useModal } from 'components/Modal';
import { ReportType } from 'api/types/report';
import { useReports } from 'providers/ReportsProvider';

import ModalComment, { ModalTaskComment } from './modal';
import Container, { TaskTextAreaContainer, TextArea, TextAreaContainer, Title, ViewAll } from './style';
import { CommentProvider, useComment } from './provider';
import { useTaskComment } from './taskProvider';
import { CounterByTask } from 'api/types/counter';

const Comment: React.FC = () => {
	const { comment, setComment } = useComment();
	const { canEdit } = useReports();
	const { push } = useModal();

	return (
		<Container>
			<Title>{i18n.t('comment_event')}</Title>
			<TextAreaContainer>
				<TextArea disabled={!canEdit} value={comment} onChange={(e) => setComment(e.target.value.substring(0, 2048 - 1))} />
				<div onClick={() => push(<ModalComment />)}>
					<ViewAll alignItems='center' justifyContent='center'>
						{i18n.t('view_all')}
					</ViewAll>
				</div>
			</TextAreaContainer>
		</Container>
	);
};


type TaskCommentProps = {
	task: CounterByTask;
}


const TaskComment: React.FC<TaskCommentProps> = ({ task }) => {
	const { comments, getCommentByTask, updateCommentByTask } = useTaskComment();
	const { canEdit } = useReports();
	const { push } = useModal();

	const [originalComment, setOriginalComment] = useState(getCommentByTask(task));
	const [comment, setComment] = useState(originalComment?.content ?? '');
	const debouncedComment = useDebounce(comment, 750);

	useEffect(() => {
		if (comment !== originalComment?.content) {
			updateCommentByTask(task, comment);
			setOriginalComment(getCommentByTask(task));
		}
	}, [debouncedComment]);

	useEffect(() => {
		const newOriginalComment = getCommentByTask(task);

		if (newOriginalComment) {
			setOriginalComment(newOriginalComment);
			if (newOriginalComment?.content) {
				setComment(newOriginalComment?.content);
			}
		}
	}, [comments, task])

	return (
		<TaskTextAreaContainer>
			<TextArea disabled={!canEdit} value={comment} onChange={(e) => setComment(e.target.value.substring(0, 2048 - 1))} />
			<div onClick={() => push(<ModalTaskComment task={task} />)}>
				<ViewAll alignItems='center' justifyContent='center'>
					{i18n.t('view_all')}
				</ViewAll>
			</div>
		</TaskTextAreaContainer>
	);
}

type Props = {
	currentReport: ReportType | null,
	updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>
}

const CommentBuilder: React.FC<Props> = ({ currentReport, updateReport }) => {
	return (
		<CommentProvider currentReport={currentReport} updateReport={updateReport}>
			<ModalProvider>
				<Comment />
			</ModalProvider>
		</CommentProvider>
	)
}

// note: don't forget to put TaskCommentProvider as parent of AccordionTask
export const CommentTaskWidget: React.FC<TaskCommentProps> = ({ task }) => {
	return (
		<ModalProvider>
			<TaskComment task={task} />
		</ModalProvider>
	)
}

export default CommentBuilder;
