import styled from 'styled-components';
import { DropdownType } from '.';

type Props = {
	color?: string;
	type?: DropdownType;
	notInteractive?: boolean;
};

const Container = styled.div<Props>`
	display: flex;
	color: ${({ color }) => color ?? 'white'};
	flex-direction: row;
	align-items: center;
	justify-content: ${({ type }) => type && type === DropdownType.Menu ? 'center' : 'unset'};
	padding: ${({ type }) => {
		if (type === DropdownType.Calendar) return '0 5px 0 15px'
		return '0 15px'
	}};
	position: ${({ type }) => type && type === DropdownType.Menu ? 'unset' : 'relative'};
	cursor: ${({ type, notInteractive }) => (type && type === DropdownType.Disabled) || notInteractive ? 'default' : 'pointer'};
`;

export const MenuWrapper = styled.div<Props>`
	position: absolute;
	top: 30px;
	right: ${({ type }) => type !== DropdownType.Modal ? '1em' : 'unset'};
	min-width: 15em;
	min-height: 1em;
	
	${({ type }) => type === DropdownType.Modal && `
		border-radius: 1rem;
		div {
			background-color: #FAFAFA;
		}
	`}
`;

export const TitlePadding = styled.div`
	padding-right: 10px;
	padding-left: 10px;
	font-weight: bold;
`;

export default Container;
